window.addEventListener('load', () => {
  let currentWindowHeight   = window.innerHeight
  let renderSync = false
  let stopTrigger = (window.innerHeight)* -1

  const forceSection      = document.getElementById('circlr-force')
  const voice             = document.getElementById('voice')
  const weblog             = document.getElementById('web-log')
  let sectForce           = forceSection.getBoundingClientRect().top + (stopTrigger / 1.5)
  //INDEX SCROLL EN¥VENTS.
  let scrollEvent = () => {
    if (!renderSync) {
      requestAnimationFrame(function () {
        renderSync = false

        let offsetYFourfoce   = forceSection.getBoundingClientRect().top - (currentWindowHeight / 1.75)
        let offsetYVoice      = voice.getBoundingClientRect().top
        let offsetYweblog     = weblog.getBoundingClientRect().top - (currentWindowHeight / 4)
        if (offsetYFourfoce <= 0 && offsetYVoice >= 0) {
          forceSection.classList.add('active')
        }

        if (offsetYVoice - (currentWindowHeight) <= 0 && offsetYweblog >= 0) {
          document.getElementById('js_parallax').style.transform = "translate(-50%," + (-60 +(offsetYVoice / 120)) + "%)"
        }
      })
    }
  } // let scroll
  //END INDEX SCROLL EN¥VENTS.
  document.addEventListener('scroll', scrollEvent, {passive: true})


  if ('serviceWorker' in navigator) {
    console.log('Found serviceWorker');
    navigator.serviceWorker.register('/sw.js')
    .then((reg) => {
      console.log('Service Worker Registered', reg);
    });
  } else {
    console.log('Not Found serviceWorker');
  }
}) //onload

